import type { CreateAccountResponse } from "@tandemdiabetes/sugarmate-api-react-client"
import { useCreateAccount as useCreateAccountApi } from "@tandemdiabetes/sugarmate-api-react-client"
import type { AxiosResponse } from "axios"
import dayjs from "dayjs"
// import { detect } from "detect-browser"
import { useCallback } from "react"
import type { GlucoseUnit } from "src/types"
import { noticeError } from "src/utils"

interface Params {
  email: string;
  password: string;
  country: string;
  units: GlucoseUnit;
  firstname: string;
  lastname: string;
  dateOfBirth: string;
}

type CreateAccount = (params: Params) => Promise<AxiosResponse<CreateAccountResponse>>

export function useCreateAccount(): CreateAccount {
  const createAccount = useCreateAccountApi()

  return useCallback(
    async (params: Params) => {
      const timezone = `${dayjs().utcOffset() / 60}` // utc offset in hours
      const language = navigator.language

      /*
       * TODO
       * set the device device_type, device_subtype, and version after sign in
       * JIRA story SUGAR-8217
       *
       *  const version = process.env.REACT_APP_SOFTWARE_VERSION
       *  const device_type = UserDeviceType.Web
       *
       *  const browser = detect()
       *  const device_subtype = browser
       *    ? `${browser.name} ${browser.version}`
       *    : "unknown"
       */

      try {
        const response = await createAccount({
          account: {
            language,
            timezone,
            email: params.email,
            password: params.password,
            country: params.country,
            units: params.units,
            firstname: params.firstname,
            lastname: params.lastname,
            birthdate: params.dateOfBirth,
          },
        })

        return response
      } catch (e) {
        noticeError(
          e,
          "CreateAccount",
          {
            language,
            timezone,
            email: params.email,
            country: params.country,
            units: params.units,
            firstname: params.firstname,
            lastname: params.lastname,
            birthdate: params.dateOfBirth,
          },
        )

        throw e
      }
    },
    [],
  )
}
