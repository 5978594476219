import {
  LargeInput,
  ValidationContainer,
  ValidationMessage,
  Dialog,
  DialogButton,
  DialogCloseX,
  DialogContent,
  DialogDescription,
  DialogHeading,
} from "@tandemdiabetes/sugarmate-react-components"
import dayjs from "dayjs"
import {
  useEffect,
  useMemo,
  useCallback,
  useRef,
  useState,
} from "react"
import type { ChangeEventHandler } from "react"

import type { RegistrationPageProps } from "./RegistrationPageProps"
import styles from "./SignUp.module.scss"

import { usePolyglot } from "src/contexts"
import { useToday } from "src/hooks"

const polyglotPrefix = "pages.sign_up.personal_information.date_of_birth."
const dateOfBirthId = "date-of-birth-id"

const dateOfBirthErrorId = "date-of-birth-error-id"

export function DateOfBirthInput(props: RegistrationPageProps) {
  const {
    registrationForm,
    updateRegistrationForm,
  } = props

  const polyglot = usePolyglot()
  const today = useToday()

  const [
    isDialogOpen,
    setIsDialogOpen,
  ] = useState<boolean>(false)

  const latestValidDateOfBirth = useMemo(
    () => dayjs(today)
      .subtract(
        18,
        "years",
      )
      .startOf("day"),
    [today],
  )

  // hasBlurred will be true if the user has blurred the input at least once
  const [
    hasBlurred,
    setHasBlurred,
  ] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const value = e.target.value

      if (value) {
        const isTooYoung = dayjs(value)
          .startOf("day")
          .isAfter(latestValidDateOfBirth)

        if (isTooYoung) {
          inputRef.current?.setCustomValidity(polyglot.t(`${polyglotPrefix}unsupported_error`))
        } else {
          inputRef.current?.setCustomValidity("")
        }
      } else {
        inputRef.current?.setCustomValidity("")
      }

      updateRegistrationForm({
        dateOfBirth: value,
      })
    },
    [
      latestValidDateOfBirth,
      updateRegistrationForm,
      polyglot,
      inputRef,
    ],
  )

  /*
   * show the warning dialog if the user has picked date that would make them
   * less than 18 years old
   */
  useEffect(
    () => {
      if (!registrationForm.dateOfBirth || !hasBlurred) {
        return
      }

      const isTooYoung = dayjs(registrationForm.dateOfBirth)
        .startOf("day")
        .isAfter(latestValidDateOfBirth)

      if (isTooYoung) {
        setIsDialogOpen(true)
      }
    },
    [
      latestValidDateOfBirth,
      registrationForm.dateOfBirth,
      hasBlurred,
    ],
  )

  /*
   * date inputs do not support the placeholder property, so we render a text
   * input that switches to a date input onFocus
   */
  const onFocus = useCallback(
    () => {
      if (inputRef.current) {
        // switch the input to a date input so the user can input a date
        inputRef.current.type = "date"
      }
    },
    [inputRef],
  )

  /*
   * on iOS the showPicker command on the date input fires before the onFocus
   * event
   *
   * changing the input type to "date" when onPointerDown fires is early
   * enough that iOS will display the date picker
   */
  const onPointerDown = useCallback(
    () => {
      if (inputRef.current) {
        // switch the input to a date input so the user can input a date
        inputRef.current.type = "date"
      }
    },
    [inputRef],
  )

  const onBlur = useCallback(
    () => {
      if (inputRef.current) {
        if (!inputRef.current.value) {
          // switch the input to a text input to display the placeholder
          inputRef.current.type = "text"
        }
      }

      // update the hasBlurred state
      setHasBlurred(true)
    },
    [
      inputRef,
      setHasBlurred,
    ],
  )

  return (
    <>
      <ValidationContainer>
        <LargeInput
          ref={inputRef}
          id={dateOfBirthId}
          placeholder={polyglot.t(`${polyglotPrefix}placeholder`)}
          className={styles.input}
          value={registrationForm.dateOfBirth}
          onChange={onChange}
          autoComplete="bday"
          required
          onFocus={onFocus}
          onPointerDown={onPointerDown}
          onBlur={onBlur}
        />
        <ValidationMessage
          id={dateOfBirthErrorId}
          invalidMessage={{
            valueMissing: polyglot.t("validation_message.value_missing"),
            customError: polyglot.t(`${polyglotPrefix}unsupported_error`),
          }}
        />
      </ValidationContainer>
      <Dialog
        open={isDialogOpen}
        onOpenChange={setIsDialogOpen}
      >
        <DialogContent>
          <DialogHeading>
            {polyglot.t(`${polyglotPrefix}dialog.heading`)}
            <DialogCloseX />
          </DialogHeading>
          <DialogDescription>
            {polyglot.t(`${polyglotPrefix}dialog.description`)}
          </DialogDescription>
          <DialogButton>
            {polyglot.t(`${polyglotPrefix}dialog.confirm_cta`)}
          </DialogButton>
        </DialogContent>
      </Dialog>
    </>
  )
}
