import { Toaster } from "@tandemdiabetes/sugarmate-react-components"
import type { ReactNode } from "react"
import { StrictMode } from "react"

import { ApiContextProvider } from "./ApiContext"
import { AuthContextProvider } from "./AuthContext"
import { CookiesContextProvider } from "./CookiesContext"
import { DarkModeContextProvider } from "./DarkModeContext"
import { DbContextProvider } from "./DbContext"
import { PageLifecycleContextProvider } from "./PageLifecycleContext"
import { PolyglotProvider } from "./PolyglotContext"
import { SessionStorageContextProvider } from "./SessionStorageContext"
import { SWRContextProvider } from "./SWRContext"

interface Props {
  children: ReactNode;
}

// https://react.dev/reference/react/StrictMode#strictmode
// we don't include the db context as a child of strict mode because it will create duplicate db instantiation errors
export function AppContext(props: Props) {
  return (
    <PageLifecycleContextProvider>
      <SessionStorageContextProvider>
        <AuthContextProvider>
          <ApiContextProvider>
            <DbContextProvider>
              <StrictMode>
                <PolyglotProvider>
                  <CookiesContextProvider>
                    <SWRContextProvider>
                      <DarkModeContextProvider>
                        <Toaster>
                          {props.children}
                        </Toaster>
                      </DarkModeContextProvider>
                    </SWRContextProvider>
                  </CookiesContextProvider>
                </PolyglotProvider>
              </StrictMode>
            </DbContextProvider>
          </ApiContextProvider>
        </AuthContextProvider>
      </SessionStorageContextProvider>
    </PageLifecycleContextProvider>
  )
}
