import {
  ActionSubmit,
  useToasterContext,
} from "@tandemdiabetes/sugarmate-react-components"
import {
  useCallback,
  useState,
  useMemo,
} from "react"
import type { FormEventHandler } from "react"

import { AccountInformationPage } from "./AccountInformationPage"
import { ConsentNoticesPage } from "./ConsentNoticesPage"
import { PasswordPage } from "./PasswordPage"
import { PersonalInformationPage } from "./PersonalInformationPage"

import type { RegistrationForm } from "./RegistrationForm"
import { defaultRegistrationForm } from "./RegistrationForm"
import { RegistrationPage } from "./RegistrationPage"
import styles from "./SignUp.module.scss"
import { PublicPageHeader } from "src/components"
import { usePolyglot } from "src/contexts"
import { useCreateAccount } from "src/hooks"
import { SignUpErrorToast } from "src/toasts"

const polyglotPrefix = "pages.sign_up."

const registrationPages = Object.freeze([
  RegistrationPage.PersonalInformation,
  RegistrationPage.ConsentNotices,
  RegistrationPage.AccountInformation,
  RegistrationPage.Password,
])

const registrationPagesObject = Object.freeze({
  [RegistrationPage.PersonalInformation]: PersonalInformationPage,
  [RegistrationPage.ConsentNotices]: ConsentNoticesPage,
  [RegistrationPage.AccountInformation]: AccountInformationPage,
  [RegistrationPage.Password]: PasswordPage,
})

interface Props {
  onAccountCreate: (form: RegistrationForm) => Promise<void>;
  goBack: () => void;
}

export function RegistrationFlow(props: Props) {
  const {
    goBack,
    onAccountCreate,
  } = props

  const [
    registrationPage,
    setRegistrationPage,
  ] = useState<RegistrationPage>(registrationPages[0])

  const [
    registrationForm,
    setRegistrationForm,
  ] = useState<RegistrationForm>(defaultRegistrationForm)

  const updateRegistrationForm = useCallback(
    (updatedRegistrationForm: Partial<RegistrationForm>) => {
      setRegistrationForm({
        ...registrationForm,
        ...updatedRegistrationForm,
      })
    },
    [
      registrationForm,
      setRegistrationForm,
    ],
  )

  const polyglot = usePolyglot()
  const { showToast } = useToasterContext()
  const createAccount = useCreateAccount()

  const previousPage: RegistrationPage | undefined = useMemo(
    () => {
      const index = registrationPages.indexOf(registrationPage)
      return registrationPages[index - 1]
    },
    [registrationPage],
  )

  const nextPage: RegistrationPage | undefined = useMemo(
    () => {
      const index = registrationPages.indexOf(registrationPage)
      return registrationPages[index + 1]
    },
    [registrationPage],
  )

  const onBackClick = useCallback(
    () => {
      if (previousPage) {
        setRegistrationPage(previousPage)
      } else {
        goBack()
      }
    },
    [
      previousPage,
      goBack,
    ],
  )

  const createAccountCallback = useCallback(
    async () => {
      try {
        await createAccount({
          email: registrationForm.emailAddress,
          password: registrationForm.password,
          country: registrationForm.countryOfResidence ?? "",
          units: registrationForm.units,
          firstname: registrationForm.firstname,
          lastname: registrationForm.lastname,
          dateOfBirth: registrationForm.dateOfBirth,
        })

        await onAccountCreate(registrationForm)
      } catch (e) {
        showToast(
          "sign-up-error-toast",
          SignUpErrorToast,
        )
      }
    },
    [
      createAccount,
      onAccountCreate,
      registrationForm,
    ],
  )

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()

      if (nextPage) {
        setRegistrationPage(nextPage)
      } else {
        void createAccountCallback()
      }
    },
    [
      nextPage,
      setRegistrationPage,
      createAccountCallback,
    ],
  )

  const Page = registrationPagesObject[registrationPage]

  return (
    <div className={styles.container}>
      <PublicPageHeader onBackClick={onBackClick}>
        {polyglot.t(`${polyglotPrefix}title`)}
      </PublicPageHeader>
      <Page
        registrationForm={registrationForm}
        updateRegistrationForm={updateRegistrationForm}
        onSubmit={onSubmit}
      >
        <ActionSubmit
          className={styles.submit}
          value={polyglot.t(`${polyglotPrefix}${nextPage ? "next_cta" : "submit_cta"}`)}
        />
      </Page>
    </div>
  )
}
