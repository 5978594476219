import { ApiContextProvider as SMApiContextProvider } from "@tandemdiabetes/sugarmate-api-react-client"
import type { ReactNode } from "react"
import { useAuthContext } from "src/contexts/AuthContext"

interface Props {
  children?: ReactNode;
}

export const baseURL: string = process.env.REACT_APP_SUGARMATE_BASE_URL ?? ""

export function ApiContextProvider(props: Props) {
  const {
    accessToken,
    isExpired,
  } = useAuthContext()

  return (
    <SMApiContextProvider
      baseURL={baseURL}
      accessToken={isExpired ? undefined : accessToken}
    >
      {props.children}
    </SMApiContextProvider>
  )
}
